<template>
  <v-container class="pa-0 body-card">
    <notification-popup
      :dialog="dialogLogout"
      :contentMsg="$t('contentDialogLogout')"
      :headerMsg="$t('Logout')"
      colorheaderMsg="error--text"
      :nameBtnNo="$t('No')"
      :nameBtnYes="$t('Yes')"
      Img="@/assets/premiummember/detail-promotion.png"
      colorIcon="danger"
      :onHandlerNo="clickNoLogout"
      :onHandlerYes="clickLogout"
    />
    <div id="spacer"></div>
    <v-container class="item-add pb-0">
      <v-row no-gutters align="center">
        <!-- <v-col cols="3" class="pa-2">
          <v-img
            v-if="gender === 'm'"
            class="around mt--5"
            :src="require('@/assets/photo-profile.png')"
          ></v-img>
          <v-img
            v-if="gender === 'f'"
            class="around mt--5"
            :src="require('@/assets/female-profile.png')"
          ></v-img>
          <v-img v-if="gender === ''" class="around mt--5" src=""></v-img>
        </v-col> -->
        <v-col cols="8">
          <h4 class="full-name-ellipsis">{{ fullName }}</h4>
          <p class="mb-0">{{ email }}</p>
          <p class="mb-0">{{ phoneNumber }}</p>
        </v-col>
        <v-col cols="4" class="px-2 text-right pt-10 pb-0">
          <!-- <img
            @click="clickEditProfile()"
            :src="require('@/assets/icon/edit.svg')"
            alt="icon"
          /> -->
          <v-icon
            v-if="$partnerACL.isShowButton('edit')"
            :color="$partnerACL.getButtonColor()"
            @click="clickEditProfile()"
            style="border-bottom:2px solid"
            >mdi-pencil
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="border-bottom"> </v-row>
      
      <v-row 
        v-for="item in $partnerACL.getSubMenu()"
        :key="item.title"
      >
        <v-col cols="12" class="pt-0 pb-0">
          <v-list-item @click="clickSubMenu(item.path)" class="pa-0">
            <v-list-item-avatar v-if="item.icon">
              <img :src="item.icon">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="black--text">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon color="grey darken"> mdi-chevron-right </v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-row class="border-bottom"> </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- <div class="item-add" v-if="role == 'PASSENGER-STUDENT'">
      <div id="spacer"></div>
      <v-container class="pt-0 pb-0">
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item @click="clickStudentMembership()" class="pa-0">
              <v-list-item-avatar class="img-add">
                <v-img
                  :src="require('@/assets/kyc/user-student.svg')"
                >
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="green-text font-weight-medium">
                  {{$t('StudentMember')}}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="grey darken"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div> -->

    <!-- <div
      v-if="role == 'PASSENGER-PARRENTS' || role == 'PASSENGER-PREMIUM'"
      id="spacer"
    ></div>
    <div
      class="item-add"
      v-if="role == 'PASSENGER-PARRENTS' || role == 'PASSENGER-PREMIUM'">
      <v-container class="pt-0 pb-0">
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item @click="clickPremiumMembership()" class="pa-0">
              <v-list-item-avatar class="img-add">
                <v-img
                  :src="require('@/assets/kyc/user-premium.svg')"
                >
                </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
              <v-list-item-title class="green-text font-weight-medium">
                {{$t('PremiumMember')}}
              </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon color="grey darken"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="spacer"></div> -->

    <!-- <div class="item-add mt-0">
      <v-container class="pb-0 pt-0">
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item v-on:click="helpCenter()" class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="black--text">
                  {{ $t("HelpCenter") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="grey darken"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="border-bottom"> </v-row>
        <v-row v-if="tos">
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item v-on:click="clickTermOfService()" class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="black--text">
                  {{ $t("TermsofService") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="grey darken"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="border-bottom"> </v-row>
        <v-row v-if="priv">
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item v-on:click="clickPrivacyPolicy()" class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="black--text">
                  {{ $t("PrivacyPolicy") }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="grey darken"> mdi-chevron-right </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row class="border-bottom"> </v-row>
      </v-container>
    </div> -->
    <div id="spacer"></div>
    <div class="item-add">
      <v-container class="pb-0 pt-0">
        <v-row>
          <v-col cols="12" class="pt-0 pb-0">
            <v-list-item v-on:click="clickDialogLogout()" class="pa-0">
              <v-list-item-content>
                <v-list-item-title class="error--text">
                  {{ $t("Logout") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="spacer"></div>
  </v-container>
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";

export default {
  name: "About",
  components: { NotificationPopup },
  data() {
    return {
      dialogLogout: false,
      role: this.$store.state.auth.user_role,
      gender: "",
      phoneNumber: "",
      fullName: "",
      email: "",
      priv: false,
      tos: false,
    };
  },
  computed: {
    provider() {
      return this.$store.state.auth.provider;
    },
  },
  mounted() {
    this.$store
      .dispatch("customer/detailCustomer")
      .then((response) => {
        this.phoneNumber = response.phoneCID + response.phoneNumber;
        this.fullName = response.fullName;
        this.gender = response.gender;
        this.email = response.email;
        // for (var item of response.enable_priv_tos) {
        //   // console.log(item);
        //   if (item.id == "1") {
        //     this.tos = item.enable;
        //   } else if (item.id == "2") {
        //     this.priv = item.enable;
        //   }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    clickSubMenu(menuPath) {
      this.$router.push({
        path: menuPath,
      });
    },
    clickStudentMembership() {
      this.$router.push({
        name: "StudentMembership",
      });
    },
    clickPremiumMembership() {
      this.$router.push({
        name: "PremiumMembership",
      });
    },
    clickUpdatePassword() {
      this.$router.push({
        name: "ChangePassword",
      });
    },
    clickMyWallet() {
      this.$router.push({
        name: "PaymentMethodList",
      });
    },
    clickLanguage() {
      this.$router.push({
        name: "SettingLanguange",
      });
    },
    clickEditProfile() {

      const editButton = this.$partnerACL.getMetaButton('edit');
      if (editButton) {
        if (editButton.menu_type == "wsc_menu") {
          this.$router.push({
            name: editButton.redirect_name,
          });
        } else {
          window.location = editButton.redirect_path;
        }
      } else if (this.$store.state.auth.provider == "GOBUS") {
        this.$router.push({
          name: "EditProfileGobus",
        });
      } else {
        this.$router.push({
          name: "EditProfile",
        });
      }
    },
    clickPrivacyPolicy() {
      this.$router.push({
        name: "PrivacyPolicy",
      });
    },
    clickTermOfService() {
      this.$router.push({
        name: "TermOfService",
      });
    },
    helpCenter() {
      this.$router.push({
        name: "HelpCenter",
      });
    },
    clickDialogLogout() {
      this.dialogLogout = true;
    },
    clickNoLogout() {
      this.dialogLogout = false;
    },
    clickLogout() {
      // let loginUrl = this.$store.state.auth.initialPath.login
      this.dialogLogout = false;
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$store.dispatch("auth/resetInitialPath", JSON.parse(localStorage.getItem("InitialPath")))
          location.reload()
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.body-card {
  background-color: #f5fff7;
  height: 100%;
  width: 100%;
  padding-top: 0;
}

.full-name-ellipsis {
  width: 250px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

p {
  font-size: 0.857rem;
}

#spacer {
  background: #f5fff7;
  padding: 6px;
}

.around {
  border-radius: 50% !important;
}

.mt--5 {
  margin-top: -5px;
}

.border-bottom {
  border-bottom: 1px solid #f5f5f5;
}

.btn-overflow {
  position: relative;
  overflow: hidden;
}

.green-text {
  color: #4bb14e;
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .background {
    width: 100%;
    height: 0.5%;
    padding-top: 0.2%;
    background-color: #f5fff7;
  }

  .item-add {
    background-color: #ffffff;
    width: 100%;
  }

  .not-verify {
    background: rgba(221, 0, 17, 0.15);
    border-radius: 15px;

    //text
    text-align: center;
  }

  .btn-edit-profile {
    margin-top: -2%;
    font-style: normal;
    font-weight: bold;
    font-size: 100px;
    line-height: 12px;
    text-transform: capitalize;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .background {
    width: 100%;
    height: 2%;
    padding-top: 1%;
    background-color: #f5fdf7;
  }

  .item-add {
    width: 100%;
    background-color: #ffffff;
  }

  .h4-title {
    padding-top: 10px;
    padding-left: 10px;
  }

  .img-add {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    margin-right: 8px !important;
  }

  .title-add {
    margin-top: 0.3%;
  }

  .not-verify {
    background: rgba(221, 0, 17, 0.15);
    border-radius: 15px;
    //text
    text-align: center;
  }

  .btn-edit-profile {
    margin-top: -3%;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: capitalize;
  }
}</style>